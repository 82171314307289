@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
	:root {
		--font-sans: Nunito Sans, Nunito Sans Fallback;
		/* --font-mono: here if you got it...

		/* prefixed with foreground because it should look good on the background */
/* --foreground-danger: 345 82.7% 40.8%;

		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 90%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem; 
	} */

@layer base {
  :root {
    --font-sans: Lato, Nunito Sans, Nunito Sans Fallback;
    --card: 0 0% 99%;
    --ring: 213 21.05% 62.75%;
    --input: 210 16.22% 85.49%;
    --muted: 0 0% 82%;
    --accent: 212.65 36.17% 36.86%;
    --border: 210 16.22% 85.49%;
    --popover: 0 0% 99%;
    --primary: 212.65 36.17% 36.86%;
    --secondary: 18.11 81.73% 61.37%;
    --background: 210 36.36% 95.69%;
    --foreground: 121 53% 5%;
    --destructive: 0 86.25% 68.63%;
    --card-foreground: 121 53% 4%;
    --muted-foreground: 121 0% 52.77%;
    --accent-foreground: 213 0% 100%;
    --popover-foreground: 121 53% 4%;
    --primary-foreground: 121 0% 100%;
    --secondary-foreground: 121 0% 100%;
    --destructive-foreground: 0 86.52% 97.73%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
  }

  .dark {
    --card: 121 6% 8%;
    --ring: 121 14% 15%;
    --input: 121 14% 10%;
    --muted: 121 40% 11%;
    --accent: 121 13% 19%;
    --border: 121 14% 10%;
    --popover: 121 40% 2%;
    --primary: 10 94% 25%;
    --secondary: 190 84.21% 29.8%;
    --background: 121 40% 1%;
    --foreground: 121 35% 97%;
    --destructive: 17 90% 45%;
    --card-foreground: 121 35% 98%;
    --muted-foreground: 121 10% 66%;
    --accent-foreground: 121 13% 79%;
    --popover-foreground: 121 35% 98%;
    --primary-foreground: 121 14% 85%;
    --secondary-foreground: 121 7% 79%;
    --destructive-foreground: 0 0% 100%;
  }
}

/* @layer base {
  :root {
    --font-sans: Lato, Nunito Sans, Nunito Sans Fallback;
    --background: 225 40% 98%;
    --foreground: 214 35% 9%;
    --card: 225 40% 98%;
    --card-foreground: 214 35% 9%;
    --popover: 225 40% 98%;
    --popover-foreground: 214 35% 9%;
    --primary: 117 78% 46%;
    --primary-foreground: 225 40% 98%;
    --secondary: 216 67% 69%;
    --secondary-foreground: 214 35% 9%;
    --muted: 225 39% 20%;
    --muted-foreground: 214 35% 35%;
    --accent: 216 78% 42%;
    --accent-foreground: 225 40% 98%;
    --destructive: 0 85% 60%;
    --destructive-foreground: 214 35% 9%;
    --border: 225 39% 20%;
    --input: 225 39% 20%;
    --ring: 216 78% 40%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 225 40% 2%;
    --foreground: 214 35% 91%;
    --card: 225 40% 2%;
    --card-foreground: 214 35% 91%;
    --popover: 225 40% 2%;
    --popover-foreground: 214 35% 91%;
    --primary: 217 78% 44%;
    --primary-foreground: 225 40% 2%;
    --secondary: 216 67% 31%;
    --secondary-foreground: 214 35% 91%;
    --muted: 225 39% 80%;
    --muted-foreground: 214 35% 91%;
    --accent: 216 78% 58%;
    --accent-foreground: 225 40% 2%;
    --destructive: 0 62% 30%;
    --destructive-foreground: 215 33% 10%;
    --border: 225 40% 60%;
    --input: 225 39% 80%;
    --ring: 216 78% 60%;
  }
} */

/* :root {

    --font-sans: Nunito Sans, Nunito Sans Fallback;
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.3rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;

  } */

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
